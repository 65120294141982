import { render, staticRenderFns } from "./shishicai.vue?vue&type=template&id=c089219c&scoped=true&"
import script from "./shishicai.vue?vue&type=script&lang=js&"
export * from "./shishicai.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c089219c",
  null
  
)

export default component.exports